import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useConfig from "../../actions/Tenants/config/configHook";

// First, define the Option interface
export interface Option {
    id: string;
    value: string;
    label: string;
}
export interface TenantDefect extends Option {
    defect_id: string;
    position: string;
    severity: string;
}

export const doFilterOptions = (object, options) => {
    // First, filter the options based on the given criteria.
    const filteredOptions = options
        .filter((i) => !object.country || !i.country || object.country.includes(i.country))
        .filter((i) => {
            if (i.field === "grower") {
                return !object.supplier || object.supplier.includes(i.supplier);
            }
            return !object.supplier || !i.supplier || object.supplier.includes(i.supplier);
        })
        // .filter((i) => !object.global_gap_number || !i.global_gap_number || i.global_gap_number === object.global_gap_number)
        .filter((i) => !object.fruit_type || !i.fruit_type || i.fruit_type === object.fruit_type);
    // Then, sort the filtered options.
    // Prioritize options based on their label, but ensure that "Other" or options with value "other" are at the end.
    const sortedOptions = filteredOptions.sort((a, b) => {
        const isOtherA = a.label?.toLowerCase() === "other" || a.value?.toLowerCase() === "other";
        const isOtherB = b.label?.toLowerCase() === "other" || b.value?.toLowerCase() === "other";

        if (isOtherA && !isOtherB) return 1; // Move 'a' to the end if it's "Other"
        if (!isOtherA && isOtherB) return -1; // Move 'b' to the end if it's "Other"

        // If neither 'a' nor 'b' is "Other", or both are, then sort alphabetically by label.
        return a.label?.localeCompare(b.label);
    });

    return sortedOptions;
};

export const doFilterDefects = (object, options) => {
    // First, filter the options based on the given criteria.
    return options
        .filter((i) => !object.defect_position || !i.position || object.defect_position.includes(i.position))
        .filter((i) => !object.defect_severity || !i.severity || object.defect_severity.includes(i.severity))
        .map((i) => ({ ...i, value: i.defect_id }));

};

export function useDefectsHook(fruit_type: string):TenantDefect[] {
    const allDefects = useSelector((state: any) => state?.tenants?.defects_by_fruit_type);
    const { tenant_id } = useConfig();
    const [filterOptions, setFilterOptions] = useState<TenantDefect[]>([]);

    useEffect(() => {
        if (allDefects) {
            setFilterOptions(allDefects?.[tenant_id]?.[fruit_type] || []);
        }
    }, [fruit_type, tenant_id, allDefects?.[tenant_id]?.[fruit_type]?.length]);

    return filterOptions;
}

export function useManagedOptionsHook({ object, name }):Option[] {
    const allOptions = useSelector((state: any) => state?.tenants?.options_by_field);
    const { tenant_id } = useConfig();
    const [filterOptions, setFilterOptions] = useState<Option[]>([]);

    useEffect(() => {
        if (allOptions) {
            setFilterOptions(doFilterOptions(object, allOptions?.[tenant_id]?.[name] || []));
        }
    }, [name, object.supplier, object.country, object.fruit_type, object.global_gap_number, tenant_id, allOptions?.[tenant_id]?.[name]?.length]);


    return filterOptions;
}

const removeDuplicates = (meta) => {
    return meta.filter((item, index, array) => {
        return array.findIndex((i) => i.value === item.value) === index;
    });
};

export function getMetaValues({ name }): Option[] {
    const layer = useSelector((state: any) => state?.layers?.current);
    const [options, setOptions] = useState<Option[]>([]);

    useEffect(() => {
        if (layer && name && layer.children) {
            const meta = layer.children.filter((i) => i[name]).map((i) => ({ label: i[name], value: i[name] }));
            setOptions(removeDuplicates(meta));
        }
    }, [name, layer]);

    return options;
}
